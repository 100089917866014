export default [
  {
    label: 'AC',
    valor: '12',
  },
  {
    label: 'AL',
    valor: '27',
  },
  {
    label: 'AP',
    valor: '16',
  },
  {
    label: 'AM',
    valor: '13',
  },
  {
    label: 'BA',
    valor: '29',
  },
  {
    label: 'CE',
    valor: '23',
  },
  {
    label: 'DF',
    valor: '53',
  },
  {
    label: 'ES',
    valor: '32',
  },
  {
    label: 'GO',
    valor: '52',
  },
  {
    label: 'MA',
    valor: '21',
  },
  {
    label: 'MT',
    valor: '51',
  },
  {
    label: 'MS',
    valor: '50',
  },
  {
    label: 'MG',
    valor: '31',
  },
  {
    label: 'PA',
    valor: '15',
  },
  {
    label: 'PB',
    valor: '25',
  },
  {
    label: 'PR',
    valor: '41',
  },
  {
    label: 'PE',
    valor: '26',
  },
  {
    label: 'PI',
    valor: '22',
  },
  {
    label: 'RJ',
    valor: '33',
  },
  {
    label: 'RN',
    valor: '24',
  },
  {
    label: 'RS',
    valor: '43',
  },
  {
    label: 'RO',
    valor: '11',
  },
  {
    label: 'RR',
    valor: '14',
  },
  {
    label: 'SC',
    valor: '42',
  },
  {
    label: 'SP',
    valor: '35',
  },
  {
    label: 'SE',
    valor: '28',
  },
  {
    label: 'TO',
    valor: '17',
  },
]

<template>
  <b-card>
    <b-row
      align-h="end"
      class="mr-0"
      style="gap: 1rem;"
    >
      <download-em-excel
        :filtro="filtroAtual"
        tipo="CNDs Estadual"
        :api="apiEstadual"
      />
      <download-em-lote
        tipo-arquivo="todas as CND's Estaduais"
        tipo="CND Estadual"
        :filtro="filtroAtual.filtroBy"
        :palavras-filtro="palavrasPersonalizadas"
        @baixar-lote="baixarEmLote"
      />
    </b-row>
    <cnd-estadual-list
      ref="cndEstadualList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :filtros-rapidos="filtrosRapidos"
      :tem-filtro-extra="true"
      :filtro-extra-custom="1"
    >
      <template slot="filtro-extra">
        <filtro-rapido
          :filtros="filtrosEstados"
          filtro-nome="UF"
          @selecionado="$refs.cndEstadualList.aplicarFiltroRapido({...$event, colunas: ['Estado'] }, false)"
        />
      </template>
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <cnd-situacao
          v-if="props.column.field === 'situacao'"
          :value="props.row[props.column.field]"
          :descricao="props.row['situacaoDesc']"
        />
        <span
          v-if="props.column.field === 'razaoSocial'"
          @click.stop="$refs[`acoes${props.row.id}`].abrirArquivo(props.row)"
        >
          {{ props.formattedRow[props.column.field] }}
        </span>
        <span
          v-if="props.column.field === 'proximaConsulta'"
          v-b-tooltip.hover
          :title="props.row[props.column.field] === '-' && props.row[`situacaoDesc`] === 'Motivo' ? 'Aguardando dados da Inscrição Estadual no cadastro da empresa' : ''"
        >
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <cnd-acoes
          :ref="`acoes${props.row.id}`"
          :grid-props="props"
          :api="apiEstadual"
          :tipo-cnd="tipoCnd"
        />
      </template>
    </cnd-estadual-list>
  </b-card>
</template>

<script>
import siglaEstados from '@/app/siglas-estados'
import colunas from './shared/components/colunas'
import api from './shared/services/api'

export default {
  name: 'CndEstadualVisualizacao',
  components: {
    CndEstadualList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    CndAcoes: () => import('@/app/shared/components/cnd-acoes/CndAcoes.vue'),
    FiltroRapido: () => import('@pilar/components/visualizacao-base/components/filtro-rapido/FiltroRapido.vue'),
    CndSituacao: () => import('@/app/shared/components/cnd-situacao/CndSituacao.vue'),
    DownloadEmLote: () => import('@app/download-lote/DownloadEmLote.vue'),
    DownloadEmExcel: () => import('@app/download-excel/DownloadEmExcel.vue'),
  },
  data() {
    return {
      apiEstadual: api,
      tipoCnd: 'estadual',
      filtrosEstados: siglaEstados,
      botoesOption: null,
      linha: null,
      visualizacao: {
        placeholderBusca: 'Buscar por: CNPJ/CPF, Razão social, Consultado em, Data de validade e próxima consulta',
        acaoClass: 'col-td-acao-3',
        disabledCheckbox: true,
      },
      filtros: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial', 'UltimaConsulta', 'DataValidade', 'ProximaConsulta'], valor: null }],
      filtrosRapidos: [
        {
          id: 'Regular',
          colunas: ['Situacao'],
          label: 'Regulares',
          valor: '1,2',
        },
        {
          id: 'Irregular',
          colunas: ['Situacao'],
          label: 'Irregulares',
          valor: '3',
        },
        {
          id: 'Motivo',
          colunas: ['Situacao'],
          label: 'Motivo não geração',
          valor: '4',
        }],
      criando: true,
      filtroAtual: {},
    }
  },
  computed: {
    palavrasPersonalizadas: {
      get() {
        const estados = siglaEstados.map(map => map.label)
        return [['Regulares', 'Irregulares', 'Motivo'], [...estados]]
      },
    },
  },
  created() {
    this.visualizacao.colunas = colunas
    this.visualizacao.evento = this.carregar
  },
  mounted() {
    this.aplicarFiltroQuery()
  },
  updated() {
    this.aplicarFiltroQuery()
  },
  methods: {
    carregar(pagina, tamanhoPagina, filtro) {
      this.filtroAtual = filtro
      return api.getPaginado(pagina, tamanhoPagina, filtro)
    },
    getColorTag(value) {
      if (value === 'Regular') {
        return 'light-success'
      }
      return value === 'Irregular' ? 'light-danger' : 'light-primary'
    },
    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params && this.$refs.cndEstadualList) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.$refs.cndEstadualList.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
    baixarEmLote(evt) {
      api.baixaEmLote(this.filtroAtual, evt)
    },
  },
}
</script>
